import sarcasm2 from '../assets/sarcasm2.m4a';
import sarcasm3 from '../assets/sarcasm3.m4a';
import rameau from '../assets/Rameau.m4a';

export const recordings = [
  { 
    id: 'rameau',
    name: "Gavotte in Am",
    composer: "Rameau",
    track: rameau,
    date: "2024-12-21",
    location: "SF Conservatory of Music"
  },
  {
    id: 'sarcasm2',
    name: "Sarcasms No. 2",
    composer: "Prokofiev",
    track: sarcasm2
  },
  {
    id: 'sarcasm3',
    name: "Sarcasms No. 3",
    composer: "Prokofiev",
    track: sarcasm3
  }
]; 